import { Box, Container } from '@material-ui/core'
import Drawer from '@material-ui/core/Drawer'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import { NavLink, Route, useParams, useRouteMatch } from 'react-router-dom'
import { useSelectFleetById } from 'src/reducers/fleet/fleet-hooks'
import { useGetWatchStatus } from 'src/reducers/fleet/fleet-hooks'
import { translateItem } from '../../utils/languageTransformer'
import DetailsHeader from './details-header'
import Notes from './notes/notes'
import { CalculationVersions } from './versions/calculation-versions'

interface SidebarMenus {
  text: string
  link: string
}

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    textDecoration: 'none',
    height: '100%',
    overflow: 'hidden',
  },
  drawer: {
    width: '13%',
    flexShrink: 0,
    zIndex: 700,
  },
  drawerPaper: {
    width: '13%',
    backgroundColor: '#545241',
    overflow: 'visible',
  },
  content: {
    flexGrow: 1,
    padding: '34px 32px 15px 70px',
    overflow: 'auto',
  },
  mainContainer: {
    marginLeft: '0',
  },
  linkText: {
    color: '#fff',
    marginBottom: '5px',
    display: 'block',
    padding: '10px 16px 10px 30px',
    textDecoration: 'none',
    '&:visited': {
      backgroundColor: '#8E0038',
      width: '105%',
      borderRadius: '0 4px 4px 0',
      boxShadow:
        '0 4px 5px 0 rgba(0,0,0,0.12), 0 1px 10px 0 rgba(0,0,0,0.1), 0 2px 4px -1px rgba(0,0,0,0.16)',
    },
    '&:hover': {
      backgroundColor: ' #999999',
      width: '105%',
      borderRadius: '0 4px 4px 0',
      boxShadow:
        '0 4px 5px 0 rgba(0,0,0,0.12), 0 1px 10px 0 rgba(0,0,0,0.1), 0 2px 4px -1px rgba(0,0,0,0.16)',
    },
  },
  activeLink: {
    backgroundColor: '#8E0038',
    width: '105%',
    borderRadius: '0 4px 4px 0',
    boxShadow:
      '0 4px 5px 0 rgba(0,0,0,0.12), 0 1px 10px 0 rgba(0,0,0,0.1), 0 2px 4px -1px rgba(0,0,0,0.16)',
  },
  toolbar: {
    minHeight: '64px',
  },
}))

const getSidebarMenus = (): SidebarMenus[] => {
  return [
    {
      text: translateItem('overview'),
      link: '/overview',
    },
    {
      text: translateItem('details'),
      link: '/details',
    },
    {
      text: translateItem('proposals'),
      link: '/versions',
    },
    {
      text: translateItem('notes'),
      link: '/notes',
    },
  ]
}

export function FleetDetails(props: any) {
  const match: any = useRouteMatch()
  const currentUrl = props.match.url
  const classes = useStyles()

  const { id = '' } = useParams()
  const selectedFleet = useSelectFleetById(id)
  const inWatchList = useGetWatchStatus(id)
  return (
    <>
      <div className={classes.root}>
        <Drawer
          className={classes.drawer}
          variant='permanent'
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <Box className={classes.toolbar} marginBottom='26px' />
          {getSidebarMenus().map(text => (
            <React.Fragment key={text.link}>
              <NavLink
                to={`${currentUrl}${text.link}`}
                className={classes.linkText}
                activeClassName={classes.activeLink}
              >
                {text.text}
              </NavLink>
            </React.Fragment>
          ))}
        </Drawer>
        <main className={classes.content}>
          <Container disableGutters={true} className={classes.mainContainer}>
            {selectedFleet ? (
              <DetailsHeader selectedFleet={selectedFleet} inWatchList={inWatchList} />
            ) : null}
            <Route
              path={`${match.url}/versions`}
              render={() => <CalculationVersions params={match.params} key={Math.random()} />}
            />
            <Route path={`${match.url}/notes`} render={() => <Notes key={Math.random()} />} />
          </Container>
        </main>
      </div>
    </>
  )
}
