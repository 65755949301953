import { createMuiTheme } from '@material-ui/core/styles'
import './styles.css'

export const theme = createMuiTheme({
  typography: {
    fontFamily: [
      'FSMeWeb',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  palette: {
    primary: {
      main: '#8E0038',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#807E6F',
    },
    action: { selected: '#FFFFFF' },
  },
  overrides: {
    MuiInputBase: {
      root: {
        fontSize: '12px',
      },
    },
    MuiTableSortLabel: {
      root: {
        '&:focus': {
          color: 'rgba(0, 0, 0, 0.54) !important',
        },
      },
      icon: {
        width: '20px',
        height: '20px',
      },
    },
    MuiSvgIcon: {
      root: {
        cursor: 'pointer',
      },
    },
    MuiInputLabel: {
      root: {
        fontSize: '12px',
      },
    },
    MuiChip: {
      deleteIcon: {
        display: 'none',
      },
    },
    MuiPaper: {
      elevation1: {
        boxShadow:
          '0 4px 5px 0 rgba(0,0,0,0.12), 0 1px 10px 0 rgba(0,0,0,0.1), 0 2px 4px -1px rgba(0,0,0,0.16)',
      },
    },
    MuiBackdrop: {
      root: {
        backgroundColor: 'transparent',
      },
    },
  },
})
