import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { TableCell as MuiTableCell } from '@material-ui/core'
import { NumberText } from '../../pages/calculation/claimHistoryTable/number-text'

const StyledTableCell = withStyles({
  root: {
    color: '#333333 !important',
    fontSize: '.75rem',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
})(MuiTableCell)

interface TableCellProps<T extends any> {
  rowItem?: any
  index?: number
  handleClick?: any
  handleCheckboxClick?: any
  selected?: any
  checkBoxRequired?: boolean
  rowIcon?: boolean
  clickable?: boolean
  children?: any
  className?: string
  numeric?: boolean
}

export function TableCell(props: TableCellProps<any>) {
  const { rowItem, index, children, className, numeric } = props
  const labelId = `enhanced-table-checkbox-${index}`
  return (
    <StyledTableCell
      component='td'
      id={labelId}
      key={index}
      scope='row'
      align='left'
      className={className}
      size='small'
    >
      {rowItem}
      {numeric ? <NumberText>{children}</NumberText> : children}
    </StyledTableCell>
  )
}
