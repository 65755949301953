import React from 'react'
import { Card } from '../../components/card/card'
import { SummaryProposalView } from '../../views/summary/summaryProposalView'

interface CalimsTableData {
  label: string
  value: any
}

interface DataProps {
  intro: string
  reason: string
  offer: string
  response: string
}

interface DetailsProps {
  brokerAddress: string
  contactPerson: string
  extension: string
  fax: string
  customerInfo: string
  date: any
}

interface SummaryProposalProps {
  calimsTableData: CalimsTableData[]
  details: DetailsProps
  handleChange: Function
  changeRecipient: Function
  selectedRecipient: string
}

export function SummaryProposal(props: SummaryProposalProps) {
  const { calimsTableData, details, handleChange, changeRecipient, selectedRecipient } = props
  return (
    <Card rounded={true}>
      <SummaryProposalView
        calimsTableData={calimsTableData}
        details={details}
        handleChange={(value: any, key: string) => handleChange(value, key)}
        changeRecipient={changeRecipient}
        selectedRecipient={selectedRecipient}
      />
    </Card>
  )
}
