import { createSaga } from 'redux-saga-tools'
import { call, put } from 'redux-saga/effects'
import { getActiveReminders, markAsDone } from './reminder-service'
import { ReminderActionTypes, ReminderActions } from './reminder-actions'

function* getReminders() {
  const reminders = yield call(getActiveReminders)
  yield put(ReminderActions.loadReminders(reminders))
}

function* markReminderAsDone(id: string) {
  yield call(markAsDone, id)
  yield put(ReminderActions.listReminders())
}

export const reminderSagas = [
  createSaga(ReminderActionTypes.LIST_REMINDERS, getReminders),
  createSaga(ReminderActionTypes.MARK_AS_COMPLETE, markReminderAsDone),
]
