/* eslint @typescript-eslint/camelcase : 0 */
import { ById } from 'redux-saga-tools'
import { Claim, ClaimSummary, Proposal } from './proposal'
const proposalsById: ById<Proposal> = {}

function parseDateString(dateString: string) {
  const day = dateString.slice(0, 2)
  const month = dateString.slice(2, 5)
  const year = dateString.slice(5)
  return new Date(`${day}/${month}/${year}`)
}

function getNoOfMonths(
  currentYear: number,
  startDateString: string,
  endDateString: string,
  risksNumber: number,
): number {
  const startDate = parseDateString(startDateString)
  if (!risksNumber || currentYear < startDate.getFullYear()) {
    return 0
  }
  const endDate = parseDateString(endDateString)

  const startMonth = currentYear > startDate.getFullYear() ? 0 : startDate.getMonth()
  const endMonth = currentYear < endDate.getFullYear() ? 11 : endDate.getMonth()
  return endMonth - startMonth + 1
}

export async function fetchClaimSummaryByFleetId(fleetId: string): Promise<ClaimSummary[]> {
  const summary = await fetch(
    `/api/proxy/?_program=%2FERGO+System%2FKRG%2FStored+Processes%2Freturn_values&_action=execute&req=fleet_calcprofit&id_fleet=${fleetId}`,
  )
    .then(response => response.json())
    .then(response => response.body || [])
    .then(profits =>
      profits.map((profit: any) => {
        const {
          id_fleet,
          guelt_sl,
          fleet_num_risks,
          num_claims,
          sum_claims,
          fleet_total_net,
          sum_reseres,
          Claim_Ratio,
          client_fleet_V_UR_BEG_DAT,
          client_fleet_END_DAT,
        } = profit
        return {
          fleetId: id_fleet,
          year: parseInt(guelt_sl),
          risks: fleet_num_risks,
          premium: parseInt(fleet_total_net.toFixed(0)),
          claims: num_claims,
          reserves: parseInt(sum_reseres.toFixed(0)),
          payments: parseInt(sum_claims.toFixed(0)),
          expense: sum_claims + sum_reseres,
          averageClaim: 0,
          cpr: 0,
          ratioOfClaim: Claim_Ratio,
          months: getNoOfMonths(
            parseInt(guelt_sl),
            client_fleet_V_UR_BEG_DAT,
            client_fleet_END_DAT,
            fleet_num_risks,
          ),
        }
      }),
    )
    .then(profits => (profits || []).sort((a: any, b: any) => a.year - b.year))

  return summary
}

export async function fetchClaimDetailsByFleetId(fleetId: string): Promise<Claim[]> {
  const claims = await fetch(
    `/api/proxy/?_program=%2FERGO+System%2FKRG%2FStored+Processes%2Freturn_values&_action=execute&req=fleet_calcclaims&id_fleet=${fleetId}`,
  )
    .then(response => response.json())
    .then(response => response.body || [])
    .then(claims =>
      claims.map(
        ({
          id_fleet,
          dim_claimyear,
          netcosts,
          deductible,
          claim_deductible_paidperyear,
          grosscosts,
          claimtype,
          claim_reserve_totalperyear,
          SCHADEN,
          claim_date,
          GLAS,
        }: any) => {
          return {
            claimNumber: SCHADEN,
            claimSegment: claimtype,
            claimYear: parseInt(dim_claimyear),
            deductible,
            deductiblePaidByErgo: claim_deductible_paidperyear,
            expenseForErgo: netcosts,
            fleetId: id_fleet,
            reserve: claim_reserve_totalperyear,
            totalExpense: grosscosts,
            claimDate: claim_date,
            glas: GLAS,
          }
        },
      ),
    )

  return claims
}

export async function getActiveProposals(fleetId: string): Promise<Proposal[]> {
  return fetch(`/api/proposal/fleetId/${fleetId}`)
    .then(response => response.json())
    .then(data => {
      const proposalList = data.map(
        ({
          _id,
          claimsByYear,
          fleetId,
          created,
          status,
          surCharge,
          totalAverageClaim,
          totalClaims,
          totalCpr,
          totalExpense,
          totalPayments,
          totalPremium,
          totalRatioOfClaim,
          totalReserves,
          totalRisks,
          version,
          deductables,
          details,
          type,
          resultPercentage1,
          resultType,
          author,
        }: any) => {
          return {
            id: _id,
            claimsByYear: claimsByYear,
            createdTs: created ? created : '0',
            fleetId: fleetId,
            deductables: deductables,
            status: status,
            surCharge: surCharge,
            totalAverageClaim: totalAverageClaim,
            totalClaims: totalClaims,
            totalCpr: totalCpr,
            totalExpense: totalExpense,
            totalPayments: totalPayments,
            totalPremium: totalPremium,
            totalRatioOfClaim: totalRatioOfClaim,
            totalReserves: totalReserves,
            totalRisks: totalRisks,
            version: version,
            resultPercentage1: resultPercentage1,
            resultType: resultType,
            content: type,
            underwriter: author,
            details: details,
            date: created ? new Date(created).toLocaleDateString('de-CH') : '',
          }
        },
      )
      return proposalList.sort(function(x: any, y: any) {
        if (x.version && y.version) return parseInt(y.version) - parseInt(x.version)
      })
    })
}

export function saveProposal(proposal: Proposal): any {
  if (proposal.status !== 'NEGOTIATION' && (proposal._id || proposal.id)) {
    let newProposal = {
      ...proposal,
      createdTs: Date.now(),
      updatedTs: Date.now(),
      created: Date.now(),
    }
    return fetch(`/api/proposal/${proposal.id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(newProposal, function(key, value) {
        if (value === Infinity) {
          return 'Infinity'
        } else if (value === -Infinity) {
          return '-Infinity'
        } else if (value !== null && value.toString() === 'NaN') {
          return 0
        }
        return value
      }),
    })
      .then(response => {
        if (response.ok) {
          return response.json()
        } else {
          throw new Error(`Something went wrong: ${response}`)
        }
      })
      .then(data => {
        newProposal = {
          ...newProposal,
          id: data._id,
          version: data.version,
        }
        proposalsById[data._id] = newProposal
        return newProposal
      })
      .catch(e => {
        throw new Error(`Something went wrong: ${e}`)
      })
  } else {
    proposal._id && delete proposal._id
    delete proposal.version

    let newProposal = {
      ...proposal,
      id: Math.ceil(Math.random() * 10000).toString(),
      createdTs: Date.now(),
      updatedTs: Date.now(),
      created: Date.now(),
    }
    return fetch('/api/proposal', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(newProposal, function(key, value) {
        if (value === Infinity) {
          return 'Infinity'
        } else if (value === -Infinity) {
          return '-Infinity'
        } else if (value !== null && value.toString() === 'NaN') {
          return 0
        }
        return value
      }),
    })
      .then(response => {
        if (response.ok) {
          return response.json()
        } else {
          throw new Error('Something went wrong')
        }
      })
      .then(data => {
        newProposal = {
          ...newProposal,
          id: data._id,
          version: data.version,
        }
        proposalsById[data._id] = newProposal
        return newProposal
      })
      .catch(e => {
        throw new Error('somethingWentWrong')
      })
  }
}

export async function fetchProposalById(id: string): Promise<any> {
  return fetch(`/api/proposal/${id}`)
    .then(response => response.json())
    .then(data => {
      const newProposalToBeRetrievedd = data
      newProposalToBeRetrievedd.id = data._id
      // !data.resultType && newProposalToBeRetrievedd.resultType = ''
      // !data.type && newProposalToBeRetrievedd.resultType = ''
      // !data.resultPercentage1 && newProposalToBeRetrievedd.resultPercentage1 =''
      proposalsById[id] = newProposalToBeRetrievedd
      return { ...(proposalsById[id] || {}) }
    })
    .catch(error => {
      console.error('Error:', error)
    })
}

export function saveReminder(proposal: Proposal): any {
  const newReminder = {
    dueDate: proposal.details.dueDateTs,
    fleetId: proposal.fleetId,
    policyId: proposal.id,
    policyHolder: proposal.fleetName,
    premium: proposal.premiumValue,
    claimRatio: proposal.claimRatio,
    proposalStatus: proposal.status,
    isComplete: false,
    proposalId: proposal.id,
    fleetName: proposal.fleetName,
    created: proposal.createdTs,
    updated: proposal.updatedTs,
  }
  return fetch('/api/reminder', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(newReminder),
  })
    .then(response => response.json())
    .then(data => {
      return data
    })
    .catch(error => {
      console.error('Error:', error)
    })
}
