import { createSaga } from 'redux-saga-tools'
import { call, put } from 'redux-saga/effects'
import { getSearchFilters, getFilterUpdatedTime } from './fleet-search-filters-service'
import { SearchFiltersActions, SearchFiltersActionTypes } from './fleet-search-filters-actions'
import moment from 'moment'

function* addSearchFilters() {
  let searchFilters
  const savedFilters = localStorage.getItem('savedSearchFilters')
  if (savedFilters) {
    searchFilters = JSON.parse(savedFilters)
    try {
      const filterUpdatedTime = yield call(getFilterUpdatedTime)
      if (
        !filterUpdatedTime ||
        !searchFilters.idDirectorate.length ||
        moment(filterUpdatedTime, 'DDMMMYYYY HH:mm:ss', 'en').isAfter(searchFilters.updatedTime)
      ) {
        searchFilters = yield call(getSearchFilters)
        searchFilters && searchFilters.idDirectorate.length && localStorage.setItem('savedSearchFilters', JSON.stringify(searchFilters))
      }
    } catch {
      console.log('Unable to check filter updated timestamp')
    }
  } else {
    searchFilters = yield call(getSearchFilters)
    searchFilters  && searchFilters.idDirectorate.length && localStorage.setItem('savedSearchFilters', JSON.stringify(searchFilters))
  }
  yield put(SearchFiltersActions.loadSearchFilters(searchFilters))
}

export const fleetSearchFiltersSaga = [
  createSaga(SearchFiltersActionTypes.ADD_SEARCH_FILTERS, addSearchFilters),
]
