import { CalculationComments } from '../../state/calculation-comments'

export async function getComments(mnr: string): Promise<CalculationComments> {
  return fetch(`/api/comment/fleetId/${mnr}`)
    .then(response => response.json() || [])
    .then(comments => comments[0])
    .then(commentItem => {
      if (commentItem) {
        const { _id, comment, fleetId } = commentItem
        return {
          commentId: _id,
          comment,
          fleetId,
        }
      }
      return commentItem
    })
}

export async function saveComments(payload: CalculationComments): Promise<CalculationComments> {
  return fetch(`/api/comment/`, {
    method: 'post',
    body: JSON.stringify(payload),
    headers: { 'Content-Type': 'application/json' },
  }).then(response => response.json() || [])
}

export async function updateComments(payload: CalculationComments): Promise<CalculationComments> {
  const commentId = payload.commentId
  delete payload.commentId
  return fetch(`/api/comment/${commentId}`, {
    method: 'put',
    body: JSON.stringify(payload),
    headers: { 'Content-Type': 'application/json' },
  }).then(response => response.json() || [])
}
