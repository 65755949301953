/* eslint-disable @typescript-eslint/camelcase */
import React, { useState, useEffect } from 'react'

import { Card } from '../../components/card/card'
import { Box } from '@material-ui/core'
import { TextFieldComponent } from '../../components/textField/textField'
import { Button } from '../../components/button/button'
import { MultiSelect } from '../../components/multiSelect/multiSelect'
import { SingleSelect } from '../../components/singleSelect/singleSelect'
import CustomizedSwitches from '../../components/switch/switch'
import { Label } from '../../components/label/label'
import { useTranslation } from 'react-i18next'
import { useLocation, useHistory } from 'react-router'
//use once api is ready for drop down values
import { useSearchFilterParams } from 'src/reducers/fleet-search-filters/fleet-search-filters-hook'
import { useCurrentUser } from 'src/reducers/user/user-hook'
import { FLEETS_PAGE } from 'src/constants/routes'

export function FleetSearchContainer(props: any) {
  const history = useHistory()
  const { addSearchFilterParams, addSearchFilters } = props
  const searchFilterParams = useSearchFilterParams()
  const [user] = useCurrentUser()
  const query = new URLSearchParams(useLocation().search)
  const [fleetSearchKeyword, setFleetSearchKeyword] = useState(
    searchFilterParams.searchString ? searchFilterParams.searchString : '',
  )
  const [uwa, setUWA] = useState([{ label: '' }])
  const [actionValue, setActionValue] = useState('active')
  const [region, setRegion] = useState([{ label: '' }])
  const [agencyNumber, setAgencyNumber] = useState([])
  const [contractType, setContractType] = useState(' ')
  const [contractStatus, setContractStatus] = useState(' ')
  const [renewalStatus, setRenewalStatus] = useState(' ')
  const [periodUnderReview, setPeriodUnderReview] = useState(' ')
  const [claimsRatio, setClaimsRatio] = useState({ min: '', max: '' })
  const [observationPeriod, setObservationPeriod] = useState(' ')
  const [watchlist, setWatchlist] = useState(false)
  const [uwaList, setUwaList] = useState([])
  const [directorateList, setDirectorateList] = useState([{ label: '' }])
  const [purList, setPurList] = useState([{ label: 'Auswählen', value: ' ' }])
  const [agentList, setAgentList] = useState([])
  const [contractList, setContractTypeList] = useState([{ label: 'Auswählen', value: ' ' }])
  const [renewalList, setRenewalList] = useState([])
  const [contractStatusList, setContractStatusList] = useState([])
  const [claimRatioPeriod, setclaimRatioPeriod] = useState([{ label: '', id: 0, value: ' ' }])
  const [contractListValue, setContractListValue] = useState('')
  const [contractStatusValue, setContractStatusValue] = useState('')
  const [renewalValue, setRenewalValue] = useState('')
  const [purValue, setPurValue] = useState('')
  const [claimRatioPeriodValue, setClaimRatioPeriodValue] = useState('')
  const [directorateValue, setDirectoratevalue] = useState([])
  const [agentValue, setAgentvalue] = useState([])
  const [uwaValue, setUwaValue] = useState([])
  const [filterArray, setFilterArray] = useState([{ UWA: '', id_directorate: '' }])

  const loggedUwaName = user && user.givenName ? `${user.givenName} ${user.surname}` : ''
  // const loggedUwaName = 'Alexander Haas'
  const { t } = useTranslation()
  const getClaimRatioPeriod = () => {
    const currentYear = new Date().getFullYear()
    const years = []
    for (let i = 0; i < 5; i++) {
      years.push({ label: (currentYear - i).toString(), id: i })
    }
    return years
  }

  interface FilterOptions {
    label: string
    value?: string
    id?: number
  }

  const contractStatusOptions: any = [
    { label: 'Auswählen', value: ' ' },
    { label: 'Aktive', value: 'Current' },
    { label: 'WiK', value: 'Paused' },
    { label: 'Storniert', value: 'Cancelled' },
  ]
  const renewalStatusOptions: any = [
    { label: 'Auswählen', value: ' ' },
    { label: t('inProgress'), value: 'inProgress' },
    { label: t('send'), value: 'sent' },
    { label: t('done'), value: 'done' },
    { label: t('canceled'), value: 'cancelled' },
    { label: t('negotiation'), value: 'negotiation' },
  ]

  const multiSelectGrp = (
    minWidth: string,
    padding: string,
    drpDownData: FilterOptions[],
    labelKey: string,
    placeholderkey: string,
    changeFn: Function,
    defaultValue?: FilterOptions[],
    actionValue?: string,
  ) => {
    return (
      <Box flexBasis={minWidth} padding={padding}>
        <Label
          value={t(labelKey).toUpperCase()}
          customStyles={{
            fontSize: '0.63rem',
            fontWeight: 'bold',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
            height: '2.1875rem',
          }}
        />
        <MultiSelect
          options={drpDownData}
          customLabel={t(placeholderkey)}
          onChangeHandler={changeFn}
          defaultValue={defaultValue}
          action={actionValue}
        />
      </Box>
    )
  }

  const singleSelectGrp = (
    disabled: boolean,
    minWidth: string,
    padding: string,
    drpDownData: FilterOptions[],
    labelKey: string,
    placeholderkey: string,
    changeFn: Function,
    defaultValue: string,
  ) => {
    return (
      <Box width={minWidth} padding={padding}>
        <Label
          value={t(labelKey).toUpperCase()}
          customStyles={{
            fontSize: '0.63rem',
            fontWeight: 'bold',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
            height: '2.1875rem',
          }}
        />
        <SingleSelect
          options={drpDownData}
          onChangeHandler={changeFn}
          variant='grey'
          size='medium'
          defaultValue={defaultValue}
          disabled={disabled}
        />
      </Box>
    )
  }
  useEffect(() => {
    const selectObject = { label: 'Auswählen', value: ' ' }
    if (addSearchFilters) {
      const defaultUwa =
        query.get('watchlist') !== 'true' &&
        addSearchFilters.UWA &&
        addSearchFilters.UWA.filter((uwa: any) => uwa.label === loggedUwaName)
      const fleetContracttypeList: any = addSearchFilters.fleetContracttype && [
        selectObject,
        ...addSearchFilters.fleetContracttype,
      ]
      const sortedPurList = addSearchFilters.PUR
        ? addSearchFilters.PUR.sort((pur1: any, pur2: any) => pur1.label - pur2.label)
        : []
      const purList: any = [selectObject, ...sortedPurList]
      const claimRatioList: any = [selectObject, ...getClaimRatioPeriod()]
      setUwaList(addSearchFilters.UWA)
      setUwaValue(
        query.get('watchlist') === 'true'
          ? addSearchFilters.UWA
          : defaultUwa && defaultUwa.length === 1
          ? defaultUwa
          : [],
      )
      setUWA(defaultUwa && defaultUwa.length === 1 ? defaultUwa[0].label : '')
      setPurList(addSearchFilters.PUR ? purList : '')
      setContractTypeList(addSearchFilters.fleetContracttype ? fleetContracttypeList : [])
      setContractStatusList(contractStatusOptions)
      setRenewalList(renewalStatusOptions)
      setclaimRatioPeriod(claimRatioList)
      setContractListValue(addSearchFilters.fleetContracttype ? fleetContracttypeList[0].value : '')
      setRenewalValue(renewalStatusOptions[0].value)
      setPurValue(addSearchFilters.PUR ? purList[0].value : '')
      setFilterArray(addSearchFilters.filters)
      if (!(fleetSearchKeyword !== '')) {
        const currentYear = `${new Date().getFullYear()}`
        setContractStatusValue('Current')
        setContractStatus('Current')
        setClaimRatioPeriodValue(currentYear)
        setObservationPeriod(currentYear)
      } else {
        setClaimRatioPeriodValue(claimRatioList[0].value)
        setContractStatusValue(contractStatusOptions[0].value)
      }
    }
  }, [addSearchFilters])

  useEffect(() => {
    if (query.get('watchlist') === 'true') {
      setWatchlist(query.get('watchlist') === 'true')
      addSearchFilterParams({
        uwaName: '',
        fleetType: '',
        fleetContractStatus: 'Current',
        claimRatioPeriod: new Date().getFullYear(),
        periodUnderReview: '',
        renewalStatus: '',
        searchString: '',
        watchlist: true,
        filterAction: 'apply',
      })
    } else if (query.get('claimRatio') === '>75') {
      setClaimsRatio({ ...claimsRatio, min: '75' })
      addSearchFilterParams({
        uwaName: loggedUwaName,
        fleetType: '',
        fleetContractStatus: 'Current',
        claimRatioPeriod: new Date().getFullYear(),
        periodUnderReview: '',
        renewalStatus: '',
        searchString: '',
        claimRatio: { min: 0.75 },
        filterAction: 'sq75',
        watchlist: false,
      })
    } else if (query.get('top') === '30') {
      addSearchFilterParams({
        uwaName: loggedUwaName,
        fleetType: '',
        fleetContractStatus: 'Current',
        claimRatioPeriod: new Date().getFullYear(),
        periodUnderReview: '',
        renewalStatus: '',
        searchString: '',
        filterAction: 'getTop30',
        watchlist: false,
      })
    } else {
      if (fleetSearchKeyword === '')
        addSearchFilterParams({
          uwaName: loggedUwaName,
          fleetContractStatus: 'Current',
          claimRatioPeriod: new Date().getFullYear(),
        })
      else {
        addSearchFilterParams({
          searchString: fleetSearchKeyword,
          filterAction: 'search',
        })
      }
    }
    return () => {
      addSearchFilterParams({})
    }
  }, [loggedUwaName])

  const getFilterCombination = (key: any, res: any, defaultList: any) => {
    const temp: { label: string }[] = []
    res.map((i: any) => temp.push({ label: i[key] }))
    const uniqueObjects =
      temp.length > 0
        ? temp.filter(
            (e: any, i: any, arr: any) => arr.findIndex((t: any) => t.label === e.label) === i,
          )
        : defaultList
    const idList = uniqueObjects
      ? uniqueObjects.sort(function(a: any, b: any) {
          return parseInt(a.label) - parseInt(b.label)
        })
      : []
    return idList
  }

  useEffect(() => {
    const res =
      uwa && filterArray.length > 0
        ? filterArray.filter((el: any) => {
            if (el.UWA !== '') return uwa ? uwa.indexOf(el.UWA) >= 0 : []
          })
        : []
    const defaultList =
      addSearchFilters && addSearchFilters.idDirectorate ? addSearchFilters.idDirectorate : []
    const directList = getFilterCombination('id_directorate', res, defaultList)
    setDirectorateList(directList)
    setRegion([])
    setDirectoratevalue([])
  }, [uwa, addSearchFilters])

  useEffect(() => {
    const dirList =
      directorateList.length > 0 && directorateList[0].label !== ''
        ? directorateList.map(function(obj: any) {
            return obj.label
          })
        : []
    const res =
      uwa && region.length > 0 && filterArray
        ? filterArray.filter((el: any) => {
            if (el.UWA !== '')
              return region
                ? region.indexOf(el.id_directorate) >= 0 && uwa.indexOf(el.UWA) >= 0
                : []
          })
        : uwa && filterArray
        ? filterArray.filter((el: any) => {
            if (el.UWA !== '')
              return dirList
                ? dirList.indexOf(el.id_directorate) >= 0 && uwa.indexOf(el.UWA) >= 0
                : []
          })
        : []
    const defaultList =
      uwa.length > 0 && uwa[0].label !== ''
        ? []
        : addSearchFilters && addSearchFilters.idAgency
        ? addSearchFilters.idAgency
        : []
    const agenList: any = getFilterCombination('id_agency', res, defaultList)
    setAgentList(agenList)
    setAgencyNumber([])
    setAgentvalue([])
  }, [region, addSearchFilters, directorateList])

  return (
    <Card rounded={true}>
      <Box display='flex' flexDirection='column' width='100%' padding='1.5rem 1rem 1.5rem 1rem'>
        <Box display='flex' flexDirection='row' width='100%'>
          <Box width='32%'>
            <TextFieldComponent
              placeholder={t('searchPlaceholder')}
              size='large'
              showCloseIcon
              value={fleetSearchKeyword}
              handleChange={value => {
                setFleetSearchKeyword(value)
              }}
              handleCustomKeyPress={e => {
                if (e.key === 'Enter') {
                  fleetSearchKeyword === ''
                    ? history.push(FLEETS_PAGE)
                    : addSearchFilterParams({
                        searchString: fleetSearchKeyword,
                        filterAction: 'search',
                      })
                }
              }}
            />
          </Box>
          <Box padding='0 1rem' width='12%'>
            <Button
              padding='0 20px'
              clickHandler={() => {
                addSearchFilterParams({
                  searchString: fleetSearchKeyword,
                  filterAction: 'search',
                })
              }}
            >
              {t('search')}{' '}
            </Button>
          </Box>
        </Box>
        <Box display='flex' flexDirection='row' paddingTop='2rem'>
          {uwaList && uwaList.length > 0
            ? multiSelectGrp(
                '15%',
                '0',
                uwaList,
                'underwriter',
                uwaValue && uwaValue.length === 1
                  ? loggedUwaName
                  : uwaValue.length === uwaList.length
                  ? 'allUnderwriter'
                  : '',
                (value: [], selected: []) => {
                  setUWA(value)
                  setUwaValue(selected)
                  setActionValue('active')
                },
                uwaValue,
                actionValue,
              )
            : null}
          {multiSelectGrp(
            '8%',
            '0 0 0 1rem',
            directorateList,
            'regionalDirectorate',
            'all',
            (value: [], selected: []) => {
              setRegion(value)
              setDirectoratevalue(selected)
            },
            directorateValue,
            'active',
          )}
          {multiSelectGrp(
            '8%',
            '0 0 0 1rem',
            agentList,
            'agentCode',
            'all',
            (value: [], selected: []) => {
              setAgencyNumber(value)
              setAgentvalue(selected)
            },
            agentValue,
            'active',
          )}
          {contractList && contractList.length > 0
            ? singleSelectGrp(
                false,
                '11%',
                '0 0 0 1rem',
                contractList,
                'contractType',
                'all',
                (value: any) => {
                  setContractType(value)
                  setContractListValue(value)
                },
                contractListValue,
              )
            : null}
          {contractStatusList && contractStatusList.length > 0
            ? singleSelectGrp(
                false,
                '11%',
                '0 0 0 1rem',
                contractStatusList,
                'contractStatus',
                'all',
                (value: any) => {
                  setContractStatus(value)
                  setContractStatusValue(value)
                },
                contractStatusValue,
              )
            : null}
          {renewalList && renewalList.length > 0
            ? singleSelectGrp(
                false,
                '10%',
                '0 0 0 1rem',
                renewalList,
                'renewal',
                'all',
                (value: any) => {
                  setRenewalStatus(value)
                  setRenewalValue(value)
                },
                renewalValue,
              )
            : null}
          {purList && purList.length > 0
            ? singleSelectGrp(
                false,
                '8%',
                '0 0 0 1rem',
                purList,
                'pur',
                'all',
                (value: any) => {
                  setPeriodUnderReview(value)
                  setPurValue(value)
                },
                purValue,
              )
            : null}
          <Box display='flex' paddingLeft='1rem' flexDirection='column'>
            <Label
              value={t('claimRatioRange').toUpperCase()}
              customStyles={{
                fontSize: '0.63rem',
                fontWeight: 'bold',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-end',
                height: '2.1875rem',
              }}
            />

            <Box width='8rem' alignItems='center' display='flex'>
              <Box display='flex' paddingRight='0.2rem'>
                <TextFieldComponent
                  value={claimsRatio.min}
                  size='large'
                  placeholder='min'
                  handleChange={value => {
                    setClaimsRatio({ ...claimsRatio, min: value })
                  }}
                />
              </Box>
              {'-'}
              <Box display='flex' paddingLeft='0.2rem'>
                <TextFieldComponent
                  value={claimsRatio.max}
                  size='large'
                  placeholder='max'
                  handleChange={value => {
                    setClaimsRatio(prevState => {
                      return { ...prevState, max: value }
                    })
                  }}
                />
              </Box>
            </Box>
          </Box>

          {claimRatioPeriod && claimRatioPeriod.length > 0
            ? singleSelectGrp(
                false,
                '10%',
                '0 0 0 0.63rem',
                claimRatioPeriod,
                'Zeitraum',
                'all',
                (value: any) => {
                  setObservationPeriod(value)
                  setClaimRatioPeriodValue(value)
                },
                claimRatioPeriodValue,
              )
            : null}

          <Box
            flexDirection='column'
            display='flex'
            alignItems='center'
            justifyContent='center'
            flexGrow='1'
          >
            <Label
              value={t('watchlist').toUpperCase()}
              customStyles={{
                fontSize: '0.63rem',
                fontWeight: 'bold',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-end',
                height: '2.1875rem',
              }}
            />
            <CustomizedSwitches
              value={watchlist}
              changeHandler={(watchlistState: boolean) => {
                setWatchlist(watchlistState)
              }}
            />
          </Box>
        </Box>
        <Box p={1} />
        <Box display='flex' padding='1rem 0 0 1rem' justifyContent='flex-end'>
          <Box paddingRight='1rem'>
            <Button
              variant='outlined'
              color='secondary'
              clickHandler={() => {
                const defaultUwa =
                  addSearchFilters &&
                  addSearchFilters.UWA &&
                  addSearchFilters.UWA.filter((uwa: any) => uwa.label === loggedUwaName)
                const currentYear = `${new Date().getFullYear()}`
                setUwaValue(defaultUwa && defaultUwa.length === 1 ? defaultUwa : [])
                setUWA(defaultUwa && defaultUwa.length === 1 ? defaultUwa[0].label : [])
                setFleetSearchKeyword('')
                setClaimsRatio({ min: '', max: '' })
                setContractListValue(
                  contractList && contractList.length > 0 ? contractList[0].value : '',
                )
                setContractType(' ')
                setContractStatusValue(contractStatusOptions[1].value)
                setContractStatus(contractStatusOptions[1].value)
                setRenewalValue(renewalStatusOptions[0].value)
                setRenewalStatus(' ')
                setPurValue(purList && purList.length > 0 ? purList[0].value : '')
                setPeriodUnderReview(' ')
                setClaimRatioPeriodValue(currentYear)
                setObservationPeriod(currentYear)
                setWatchlist(false)
                setDirectoratevalue([])
                setRegion([])
                setAgentvalue([])
                setAgencyNumber([])
                setActionValue('reset')
                addSearchFilterParams({
                  filterAction: 'reset',
                  uwaName: loggedUwaName,
                  fleetContractStatus: 'Current',
                  claimRatioPeriod: new Date().getFullYear(),
                })
              }}
              padding='0 20px'
              size='small'
            >
              {t('reset')}{' '}
            </Button>
          </Box>
          <Button
            variant='outlined'
            clickHandler={() => {
              addSearchFilterParams({
                searchString: fleetSearchKeyword,
                uwaName: uwa,
                brokerDirectorate: region,
                brokerAgency: agencyNumber,
                fleetType: contractType,
                fleetContractStatus: contractStatus,
                renewalStatus: renewalStatus,
                periodUnderReview: periodUnderReview,
                claimRatioPeriod: observationPeriod,
                watchlist: watchlist,
                claimRatio: {
                  min:
                    claimsRatio.min &&
                    (parseFloat(claimsRatio.min.replace(',', '.')) / 100).toFixed(3).toString(),
                  max:
                    claimsRatio.max &&
                    (parseFloat(claimsRatio.max.replace(',', '.')) / 100).toFixed(3).toString(),
                },
                filterAction: 'apply',
              })
            }}
            size='large'
            padding='0 35px'
          >
            {t('apply')}{' '}
          </Button>
        </Box>
      </Box>
    </Card>
  )
}
