import { createSaga } from 'redux-saga-tools'
import { call, put } from 'redux-saga/effects'
import { UserActionTypes, UserActions } from './user-actions'
import { fetchCurrentUser, fetchPingAPI } from './user-service'

function* getCurrentUser() {
  const user = yield call(fetchCurrentUser)
  yield put(UserActions.setCurrentUser(user))
}
function* fetchPing() {
  yield call(fetchPingAPI)
}

export const userSagas = [
  createSaga(UserActionTypes.GET_CURRENT_USER, getCurrentUser),
  createSaga(UserActionTypes.GET_PING, fetchPing),
]
