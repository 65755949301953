import { isProd } from 'src/utils'

interface StageLink {
  dev: string
  live: string
}

export const SAS_REPORT_URL: StageLink = {
  dev: 'https://sasviyafa.services.ergodev',
  live: 'https://sasviya2.services.ergo',
}
export const SAS_VA_QUICK_LINK: StageLink = {
  dev: 'https://sasviyafa.services.ergodev/SASDrive/',
  live: 'https://sasviya2.services.ergo/SASDrive/',
}

export default function envLink(linkName: StageLink): string {
  if (isProd()) {
    return linkName.live
  }
  return linkName.dev
}
