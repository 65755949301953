import { Box, Button, CircularProgress, makeStyles, Paper } from '@material-ui/core'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { DataTable, Column } from '../../components/data-table/data-table'
import AlertDialog from '../../components/dialog/dialog'
import { Label } from '../../components/label/label'
import { Reminder } from '../../state/reminder'
import { ReactComponent as ArrowIcon } from './arrow.svg'
import moment from 'moment'
import { NumberText } from '../../pages/calculation/claimHistoryTable/number-text'
import DeleteIcon from '@material-ui/icons/Delete'
import { SUMMARY_PAGE } from 'src/constants/routes'

interface ReminderListViewProps {
  isLoading?: boolean
  reminders?: Reminder[]
  onMarkReminderAsDone?: (id: string) => void
  reminderMarkAsDoneLoading?: boolean
}

const useStyles = makeStyles({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flex: 'auto',
  },
  checkboxCell: {
    padding: 0,
    verticalAlign: 'middle',
    textAlign: 'center',
  },
  paper: {
    position: 'relative',
    paddingLeft: '1rem',
    paddingRight: '1rem',
    width: '100%',
    height: '100%',
    overflowY: 'auto',
    display: 'flex',
    flex: 'auto',
    maxHeight: '365px',
  },
  progress: {
    position: 'absolute',
    left: 0,
    right: 0,
    margin: 'auto',
    top: '40%',
    width: '1rem',
    opacity: '80%',
  },
  roundButton: {
    height: '2.25rem',
    width: '2.25rem',
    paddingLeft: '0.9rem',
    minWidth: '2.25rem',
    borderRadius: '2.25rem',
  },
  width65: {
    minWidth: '95px',
  },
})

export default function ReminderListView(props: ReminderListViewProps) {
  const { reminders = [], isLoading, onMarkReminderAsDone, reminderMarkAsDoneLoading } = props
  const [selectedReminder, setSelectedReminder] = useState()
  const currencySymbol = '\u20AC'
  const { t } = useTranslation()
  const history = useHistory()
  const classes = useStyles()
  const markAsDone = (markAsDoneFlag: any) => {
    markAsDoneFlag &&
      selectedReminder &&
      onMarkReminderAsDone &&
      onMarkReminderAsDone(selectedReminder)
    setSelectedReminder(undefined)
  }

  const onItemClick = (id: string) => setSelectedReminder(id)

  const navigateToProposal = (record: any) => {
    history.push(`${SUMMARY_PAGE}/${record.fleetId}/${record.proposalId}`)
  }

  const columns: Column<any>[] = [
    {
      uniqueId: 'id1',
      key: 'id',
      title: '',
      rowClassName: classes.checkboxCell,
      render: (id: string, record: any) => (
        <DeleteIcon onClick={() => onItemClick(record.fleetId)} />
      ),
    },
    {
      uniqueId: 'dueDate',
      key: 'dueDate',
      title: t('dueTo'),
      render: (dueDate: any) => <div>{moment.unix(dueDate).format('DD/MM/YYYY')}</div>,
    },
    { uniqueId: 'policyHolder', key: 'policyHolder', title: t('policyHolder') },
    { uniqueId: 'fleetId', key: 'fleetId', title: t('fleetNo') },
    {
      uniqueId: 'premium',
      key: 'premium',
      title: t('premiumValue'),
      rowClassName: classes.width65,
      render: (item: any) => (
        <span>
          <NumberText precision={0}>{item}</NumberText>
          {` ${currencySymbol}`}
        </span>
      ),
    },
    {
      uniqueId: 'claimRatio',
      key: 'claimRatio',
      title: t('sqValue'),
      rowClassName: classes.width65,
      render: (item: any) =>
        item && (
          <span>
            <NumberText precision={1} minPrecision={1}>
              {parseFloat(item)}
            </NumberText>{' '}
            %
          </span>
        ),
    },
    {
      uniqueId: 'id2',
      key: 'id',
      title: '',
      rowClassName: classes.checkboxCell,
      render: (id: string, record: any) => (
        <Button className={classes.roundButton} onClick={() => navigateToProposal(record)}>
          <ArrowIcon className='arrow' />
        </Button>
      ),
    },
  ]

  return (
    <Box display='flex' flex='auto' flexDirection='column' height='100%' width='100%'>
      <Label value={t('reminders')} customStyles={{ marginBottom: '8px' }} />
      <Paper className={classes.paper}>
        {(isLoading || reminderMarkAsDoneLoading) && (
          <CircularProgress className={classes.progress} />
        )}
        <Box className={classes.root}>
          <DataTable columns={columns} data={reminders} />
        </Box>
      </Paper>
      <AlertDialog open={!!selectedReminder} title={t('confirmation')} handleClose={markAsDone}>
        {t('confirmDelete')}?
      </AlertDialog>
    </Box>
  )
}
