// import data from './data.json'
import { FleetNotes } from '../../state/note.js'

export async function getNotes(mnr: string): Promise<FleetNotes> {
  return fetch(`/api/note/fleetId/${mnr}`)
    .then(response => response.json() || [])
    .then(notes => notes[0])
    .then(noteItem => {
      if (noteItem) {
        const { _id, note, fleetId, created, updated } = noteItem
        return {
          commentId: _id,
          note,
          fleetId,
          created,
          updated,
        }
      }
      return noteItem
    })
}

export async function saveNotes(payload: FleetNotes): Promise<FleetNotes> {
  return fetch(`/api/note/`, {
    method: 'post',
    body: JSON.stringify(payload),
    headers: { 'Content-Type': 'application/json' },
  }).then(response => response.json() || [])
}

export async function updateNotes(payload: FleetNotes): Promise<FleetNotes> {
  const commentId = payload.commentId
  delete payload.commentId
  return fetch(`/api/note/${commentId}`, {
    method: 'put',
    body: JSON.stringify(payload),
    headers: { 'Content-Type': 'application/json' },
  }).then(response => response.json() || [])
}
