import { createSaga } from 'redux-saga-tools'
import { call, put } from 'redux-saga/effects'
import { NoteActionTypes, NoteActions } from './notes-actions'
import { getNotes, saveNotes, updateNotes } from './notes-service'
import { FleetNotes } from '../../state/note'

function* getFleetNotes(mnr: string) {
  const fleetNotes = yield call(getNotes, mnr)
  yield put(NoteActions.loadNote(fleetNotes))
}

function* saveFleetNotes(payload: FleetNotes) {
  yield call(saveNotes, payload)
}

function* updateFleetNotes(payload: FleetNotes) {
  yield call(updateNotes, payload)
}

export const fleetNoteSagas = [
  createSaga(NoteActionTypes.GET_NOTE, getFleetNotes),
  createSaga(NoteActionTypes.SAVE_NOTE, saveFleetNotes),
  createSaga(NoteActionTypes.UPDATE_NOTE, updateFleetNotes),
]
