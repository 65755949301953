import { createSaga } from 'redux-saga-tools'
import { call, put } from 'redux-saga/effects'
import { getActiveDeductables } from './deductable-service'
import { DeductableActionTypes, DeductableActions } from './deductable-actions'

function* getDeductables() {
  const deductables = yield call(getActiveDeductables)
  yield put(DeductableActions.loadDeductables(deductables))
}

export const deductableSagas = [createSaga(DeductableActionTypes.LIST_DEDUCTABLES, getDeductables)]
