/* eslint-disable @typescript-eslint/camelcase */
import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Container, WithStyles, withStyles } from '@material-ui/core'
import { Route, RouteComponentProps, Switch } from 'react-router-dom'

import { Header } from 'src/components/header/header'
import SasDetailsContainer from './SasDetailsContainer'
import SasOverviewContainer from './SasOverviewContainer'
import SasDashboardContainer from './SasDashboardContainer'
import { FleetDetails } from 'src/pages/fleet-details/fleet-details'
import { FleetSearchPage } from 'src/pages/fleet-search-page/fleet-search-page'
import { Calculation } from 'src/pages/calculation/calculation-page'
import { SummaryComponent } from 'src/pages/summary/summaryPage'
import { HomePage } from 'src/pages/home/home-page'
import { printLogs } from 'src/utils'

import { getFleetId, user as userAction } from './selectors'
import { AppState } from 'src/state/app-state'
import { DASHBOARD_PAGE, FLEET_DETAILS_PAGE } from 'src/constants/routes'

declare global {
  interface Window {
    vaReportComponents: any
  }
}
enum SAS_REPORT {
  dashboardPage = 'dashboardPage',
  overviewPage = 'overviewPage',
  detailsPage = 'detailsPage',
  noSasReports = 'noSasReports',
}

const styles = {
  sasWrapper: {
    position: 'absolute' as 'absolute',
    top: '9.8rem',
    left: 'calc(13% + 70px)',
    width: 'calc(85% - 70px)',
    maxWidth: '1280px',
  },
  displayBlock: {
    display: 'block',
  },
  displayNone: {
    display: 'none',
  },
}

type Visibility = {
  dashboard: boolean
  overview: boolean
  detailsAndEsaTable: boolean
}
export interface SasReportsContainerProps extends RouteComponentProps, WithStyles<typeof styles> {
  user: any
  fleetId: string | undefined
}

export interface SasReportsContainerState {
  visibility: Visibility
}

class SasReportsContainer extends React.Component<
  SasReportsContainerProps,
  SasReportsContainerState
> {
  constructor(props: SasReportsContainerProps) {
    super(props)
    this.state = {
      visibility: {
        dashboard: true,
        overview: false,
        detailsAndEsaTable: false,
      },
    }
  }
  static propTypes = {
    user: PropTypes.shape({
      location: PropTypes.string,
    }),
  }

  static defaultProps = {
    user: {
      location: '',
    },
  }

  componentDidMount() {
    const { location } = this.props
    this.setReportsVisibility()
    if (window.vaReportComponents) {
      this.updateSasDashboardReport()
      //only if first page opened in the tool is fleet-details page (so we have fleetId)
      if (location.pathname.includes(FLEET_DETAILS_PAGE)) {
        printLogs('componentDidMount refresh')
        this.updateSasOverviewReport()
        this.updateSasDetailsReport()
      }
    }
    window.addEventListener('vaReportComponents.loaded', this.runAllSasReportsUpdate)
  }

  componentWillUnmount() {
    window.removeEventListener('vaReportComponents.loaded', this.runAllSasReportsUpdate)
  }

  componentDidUpdate(
    prevProps: SasReportsContainerProps,
    prevState: SasReportsContainerState,
  ): void {
    //needed when navigating within app
    //as there are multiple places which can change reports visibility
    //we run it here one more time
    //TODO run setReportsVisibility on redux action
    printLogs('this.props.location.pathname:', this.props.location.pathname)
    printLogs('prevProps.location.pathname:', prevProps.location.pathname)
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.setReportsVisibility()
      //similarly as with visibility, we need to update SAS reports
      // but only on pages where sas reports are displayed
      if (window.vaReportComponents) {
        const prevFleetId = this.getFleetId(prevProps.location.pathname)
        const currentFleetId = this.props.fleetId || this.getFleetId(this.props.location.pathname)
        printLogs('prevFleetId:', prevFleetId)
        printLogs('currentFleetId:', currentFleetId)
        if (currentFleetId && prevFleetId === undefined && prevFleetId !== currentFleetId) {
          printLogs('sas reports updated')
          this.updateSasDetailsReport()
          this.updateSasOverviewReport()
        }
        //not sure if this ever happens...
        if (prevProps.user.location !== this.props.user.location) {
          this.updateSasDashboardReport()
        }
      }
    }
  }

  shouldComponentUpdate(
    nextProps: Readonly<SasReportsContainerProps>,
    nextState: Readonly<SasReportsContainerState>,
  ): boolean {
    // only update when location, fleetId  or sas report display changed
    const location = this.props.user?.location || ''
    const nextLocation = nextProps.user?.location || ''
    if (
      this.props.location.pathname !== nextProps.location.pathname ||
      location !== nextLocation ||
      this.state.visibility !== nextState.visibility
    ) {
      return true
    }
    return false
  }

  private checkPage(url: string = window.location.pathname): SAS_REPORT {
    if (url === DASHBOARD_PAGE) {
      return SAS_REPORT.dashboardPage
    } else if (url.includes('/overview') && url.includes(FLEET_DETAILS_PAGE)) {
      return SAS_REPORT.overviewPage
    } else if (url.includes('/details') && url.includes(FLEET_DETAILS_PAGE)) {
      return SAS_REPORT.detailsPage
    } else {
      return SAS_REPORT.noSasReports
    }
  }

  private setReportsVisibility(): void {
    const page: string = this.checkPage(window.location.pathname)
    switch (page) {
      case SAS_REPORT.dashboardPage:
        this.setState({
          visibility: {
            dashboard: true,
            overview: false,
            detailsAndEsaTable: false,
          },
        })
        break
      case SAS_REPORT.overviewPage:
        this.setState({
          visibility: {
            dashboard: false,
            overview: true,
            detailsAndEsaTable: false,
          },
        })
        break
      case SAS_REPORT.detailsPage:
        this.setState({
          visibility: {
            dashboard: false,
            overview: false,
            detailsAndEsaTable: true,
          },
        })
        break
      case SAS_REPORT.noSasReports:
      default:
        this.setState({
          visibility: {
            dashboard: false,
            overview: false,
            detailsAndEsaTable: false,
          },
        })
    }
  }

  private getFleetId(pathName: string): string | undefined {
    const url = pathName.split('/')
    if (
      url.length === 5 &&
      url[2] === 'fleet-details' &&
      (url[4] === 'details' || url[4] === 'overview' || url[4] === 'versions' || url[4] === 'notes')
    ) {
      return url[3]
    }
    return undefined
  }

  private updateSasDashboardReport = (): void => {
    const sasReportDashboard: any = document.getElementById('sas-report-ve9744-dashboard')
    const location = this.props?.user?.location
    sasReportDashboard.getReportHandle().then((reportHandle: any) => {
      reportHandle.updateReportParameters({
        Handler_Dashboard: location,
      })
    })
  }
  private updateSasDetailsReport = (): void => {
    if (this.props && this.props.location) {
      const fleetId = this.getFleetId(this.props.location.pathname)
      const sasReportDetails: any = document.getElementById('sas-report-ve5980-details')
      sasReportDetails.getReportHandle().then((reportHandle: any) => {
        reportHandle.updateReportParameters({
          Handler_FleetID: fleetId,
        })
      })
    }
  }
  private updateSasOverviewReport = (): void => {
    if (this.props && this.props.location) {
      const fleetId = this.getFleetId(this.props.location.pathname)
      const sasReportOverview: any = document.getElementById('sas-report-ve3928-overview')
      sasReportOverview.getReportHandle().then((reportHandle: any) => {
        reportHandle.updateReportParameters({
          Handler_FleetID: fleetId,
        })
      })
    }
  }

  private runAllSasReportsUpdate = (): void => {
    printLogs('runAllSasReportsUpdate:', new Date())
    this.updateSasDashboardReport()
    this.updateSasDetailsReport()
    this.updateSasOverviewReport()
  }

  render() {
    const {
      match: { path },
      classes,
    } = this.props
    const { dashboard, overview, detailsAndEsaTable } = this.state.visibility
    return (
      <>
        <Header />
        <Container disableGutters={true}>
          <div
            className={`HomePage__container ${
              dashboard === true ? classes.displayBlock : classes.displayNone
            }`}
            style={{ padding: '2rem 0.5rem 0 0.5rem' }}
          >
            <SasDashboardContainer />
          </div>
          <div
            className={`Overview__container ${classes.sasWrapper} ${
              overview === true ? classes.displayBlock : classes.displayNone
            }`}
          >
            <SasOverviewContainer />
          </div>
          <div
            className={`Details__container ${classes.sasWrapper} ${
              detailsAndEsaTable === true ? classes.displayBlock : classes.displayNone
            }`}
          >
            <SasDetailsContainer />
          </div>
        </Container>
        <Switch>
          <Route path={`${path}/fleet-details/:id`} component={FleetDetails} />
          <Route path={`${path}/fleets`} component={FleetSearchPage} />
          <Route path={`${path}/calculation/:fleetId/:proposalId`} component={Calculation} />
          <Route path={`${path}/calculation/:fleetId`} component={Calculation} />
          <Route path={`${path}/summary/:fleetId/:proposalId`} component={SummaryComponent} />
          <Route path={`${path}/summary/:fleetId`} component={SummaryComponent} />
          <Route path={`${path}/dashboard`} component={HomePage} />
        </Switch>
      </>
    )
  }
}

const mapStateToProps = (state: AppState) => ({
  user: userAction(state.users),
  fleetId: getFleetId(state.fleets),
})

export default withStyles(styles)(connect(mapStateToProps)(SasReportsContainer))
