import * as jsPDF from 'jspdf'
import 'jspdf-autotable'
import { imgData } from './ergo-logo'
import moment from 'moment'
import { Claim } from '../../reducers/proposal/proposal'

export const pdfDownload = (
  details: any,
  selectedRecipient: string,
  calimsTableData: any,
  claimData: Claim[],
  fleetName: string,
  marginValue: number,
) => {
  const marginLeft = 26
  const normalFontSize = 10
  const smallFontSize = 7
  const currentDate = moment().format('YYYY-MM-DD-hhmm')
  const date = moment.unix(details.proposalDate).format('DD.MM.YYYY')
  const validFromDate = moment.unix(details.validFromDate).format('DD.MM.YYYY')
  const previousYear = new Date().getFullYear() - 1
  const columns = [
    { title: 'Schaden', dataKey: 'claimNumber' },
    { title: 'Sparte', dataKey: 'claimSegment' },
    { title: 'Schadentag', dataKey: 'claimDate' },
    { title: 'Ges. Aufwand', dataKey: 'expenseForErgo' },
  ]
  const doc: any = new jsPDF.default({
    orientation: 'portrait',
    format: 'a4',
  })
  doc.addImage(imgData, 'JPEG', 153, 15, 43, 18)
  doc.setFontType('normal')
  doc.setFontSize(8)
  doc.text(`ERGO`, marginLeft, 50)
  doc.setFontType('normal')
  doc.setFontSize(7)
  doc.text(`Versicherung AG - 40198 Düsseldorf`, marginLeft + 9, 50)
  doc.setFontSize(normalFontSize)
  doc.text(details[selectedRecipient], marginLeft, 65)
  doc.setFontType('bold')
  doc.text('Ihr Ansprechpartner:', 158, 50)
  doc.setFontSize(normalFontSize)
  doc.setFontType('normal')
  doc.text(
    `Kfz-Flottengeschäft\n${details.contactPerson ? details.contactPerson + '\n' : ''}${
      details.extension ? details.extension + '\n' : ''
    }service@ergo.de\nwww.ergo.de`,
    158,
    55,
  )
  doc.text(date, 158, 80)
  doc.setFontType('bold')
  doc.text(`${details.customerInfo} \nVertragliche Anpassung zum ${validFromDate}`, marginLeft, 115)
  doc.setFontType('normal')
  doc.autoTable([{ title: '', dataKey: 'intro' }], [{ intro: details.intro }], {
    startY: 120,
    margin: { horizontal: 25 },
    styles: { overflow: 'linebreak' },
    bodyStyles: { valign: 'top', fillColor: '#fff' },
    theme: 'plain',
  })
  let lastPosY = doc.autoTableEndPosY()
  doc.autoTable({
    body: [
      ['Zeitraum', calimsTableData[1].label, details.purBasedYear],
      ['Schadenquote', calimsTableData[1].value, details.purBasedClaimRatio],
    ],
    startY: lastPosY,
    showHead: 'never',
    margin: { horizontal: 25 },
    styles: { overflow: 'linebreak' },
    bodyStyles: { valign: 'top', fillColor: '#fff' },
    theme: 'plain',
  })
  lastPosY = doc.autoTableEndPosY()
  doc.autoTable([{ title: '', dataKey: 'reason' }], [{ reason: details.reason }], {
    showHead: 'never',
    startY: lastPosY,
    margin: { horizontal: 25 },
    styles: { overflow: 'linebreak' },
    bodyStyles: { valign: 'top', fillColor: '#fff' },
    tableWidth: 170,
    theme: 'plain',
  })
  lastPosY = doc.autoTableEndPosY()
  doc.autoTable(
    [{ title: '', dataKey: 'offer' }],
    [{ offer: details.offer.replace('#VALID_DATE#', validFromDate) }],
    {
      showHead: 'never',
      startY: lastPosY,
      margin: { horizontal: 25 },
      styles: { overflow: 'linebreak' },
      bodyStyles: { valign: 'top', fillColor: '#fff' },
      tableWidth: 170,
      theme: 'plain',
    },
  )
  lastPosY = doc.autoTableEndPosY()
  doc.autoTable(
    [{ title: '', dataKey: 'responseDateAndEnding' }],
    [
      {
        responseDateAndEnding: details.responseDateAndEnding.replace(
          '#DUE_DATE#',
          moment.unix(details.dueDateTs).format('DD.MM.YYYY'),
        ),
      },
    ],
    {
      showHead: 'never',
      startY: lastPosY,
      margin: { horizontal: 25 },
      styles: { overflow: 'linebreak' },
      bodyStyles: { valign: 'top', fillColor: '#fff' },
      theme: 'plain',
    },
  )
  doc.setFontSize(smallFontSize)
  doc.setTextColor(100)
  doc.setFontType('bold')
  doc.text(`ERGO Versicherung AG`, marginLeft, 277)
  doc.setFontSize(smallFontSize)
  doc.setTextColor(100)
  doc.setFontType('normal')
  doc.text(
    `Vorsitzender des Aufsichtsrats: Dr. Clemens Muth\nVorstand: Mathias Scheuber (Vorsitzender),\nDr. Christian Gründl, Christian Molt,\nAndrea Mondry, Heiko Stüber\nSitz: Düsseldorf, Handelsregister: Amtsgericht Düsseldorf,\nHRB 36466, USt-IdNr. DE812572415, VersSt-Nr. 9116/810/00838`,
    marginLeft,
    280,
  )
  doc.setFontSize(smallFontSize)
  doc.setFontType('bold')
  doc.text(`Hausanschrift`, 110, 277)
  doc.setFontSize(smallFontSize)
  doc.setFontType('normal')
  doc.setTextColor(100)
  doc.text(
    `ERGO Versicherung AG\nERGO-Platz 1, 40198 Düsseldorf`,
    110,
    280,
  )
  doc.setFontSize(smallFontSize)
  doc.setFontType('bold')
  doc.text(`Internet: `, 110, 287)
  doc.setFontSize(smallFontSize)
  doc.setFontType('normal')
  doc.setTextColor(100)
  doc.text(
    `www.ergo.de`,
    110,
    290,
  )
  doc.setFontSize(smallFontSize)
  doc.setFontType('bold')
  doc.text(`Bankverbindung: `, 150, 277)
  doc.setFontSize(smallFontSize)
  doc.setFontType('normal')
  doc.setTextColor(100)
  doc.text(`UniCredit Bank, `, 171, 277)
  doc.setFontSize(smallFontSize)
  doc.setFontType('normal')
  doc.setTextColor(100)
  doc.text(`IBAN DE67 3022 0190 0004 3600 60\nBIC HYVEDEMM414`, 150, 280)
  doc.addPage()
  doc.setFontSize(normalFontSize)
  doc.setFontType('bold')
  doc.setTextColor(0)
  doc.text(`Einzelschadenaufstellung | ${previousYear}`, marginLeft, 15)
  doc.autoTable(columns, claimData, {
    startY: 20,
    headStyles: { fillColor: '#8E0038' },
    margin: { horizontal: marginLeft },
    styles: { overflow: 'linebreak' },
    bodyStyles: { valign: 'top' },
    theme: 'grid',
    didParseCell: (data: any) => {
      if (data.section === 'body' && Array.isArray(data.cell.text)) {
        if (data.column.dataKey === 'expenseForErgo') {
          const amount = Math.round(parseFloat(data.cell.text)).toLocaleString('de')
          data.cell.text = `${amount} \u20AC`
        } else if (data.column.dataKey === 'claimDate') {
          data.cell.text = moment(data.cell.text, 'DD/MM/YYYY').format('DD.MM.YYYY')
        }
      }
    },
  })
  doc.save(`${currentDate}-Renewal-${fleetName}.pdf`)
}
