export async function getActiveReminders(): Promise<any> {
  return fetch(`/api/reminder/user/active`)
    .then(response => {
      return response.json()
    })
    .then(rem => {
      const reminderList = rem.map(function(row: any) {
        return {
          id: row._id,
          createdTs: row.created,
          dueDate: row.dueDate,
          fleetId: row.fleetId,
          ownerId: row.authorId,
          policyId: row.policyId,
          policyHolder: row.policyHolder,
          premium: row.premium,
          claimRatio: row.claimRatio,
          proposalStatus: row.proposalStatus,
          isComplete: row.isComplete,
          proposalId: row.proposalId,
        }
      })
      return reminderList.sort(function(x: any, y: any) {
        return parseInt(x.dueDate) - parseInt(y.dueDate)
      })
    })
}

export async function markAsDone(id: string): Promise<string> {
  return fetch(`/api/reminder/current/fleetId/${id}`, {
    method: 'DELETE',
  }).then(response => response.json())
}
